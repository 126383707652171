/**
 *
 * Creative
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import EpisodeTypeInput from './EpisodeTypeInput/EpisodeTypeInput';
import GenreInput from './GenreInput/GenreInput';
import InitialReleaseInput from './InitialReleaseInput/InitialReleaseInput';
import ShootLocationDescriptionInput from './ShootLocationDescriptionInput/ShootLocationDescriptionInput';
import TitleInput from './TitleInput/TitleInput';
import type { PropsType } from './types';

const Creative: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const { creativeDetails, configTypes, onUpdateData } = props;

  const { genreValues, releasePlatformValues } = configTypes ?? {};
  const sortedGenreValues = Array.isArray(genreValues)
    ? [...genreValues].sort((a, b) => a.order - b.order)
    : [];
  const sortedReleasePlatformValues = Array.isArray(releasePlatformValues)
    ? [...releasePlatformValues].sort((a, b) => a.order - b.order)
    : [];

  // state captures all of the state info from our controls
  const [combinedState, setCombinedState] = useState({
    title: '',
    genreTypeNames: [],
    single: true,
    noOfEpisodes: 1,
    duration: 0,
    initialReleasePlatformTypeNames: [],
    shootLocation: '',
    shortDescription: '',
  });

  useEffect(() => {
    if (creativeDetails) {
      setCombinedState((prevState) => {
        // $FlowFixMe
        const newCombinedState: any = { ...prevState, ...creativeDetails };
        onUpdateData(newCombinedState);
        return newCombinedState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStateChange = (key: string, value: any) => {
    setCombinedState((prev) => {
      const newCombinedState: any = { ...prev, [key]: value };
      onUpdateData(newCombinedState);
      return newCombinedState;
    });
  };

  const {
    title,
    genreTypeNames,
    single,
    noOfEpisodes,
    duration,
    initialReleasePlatformTypeNames,
    shootLocation,
    shortDescription,
  } = combinedState;

  return (
    <Grid container spacing={2}>
      <TitleInput title={title} handleStateChange={handleStateChange} />
      <Grid container item>
        <Grid item xs>
          <GenreInput
            genreTypeNames={genreTypeNames}
            genreValues={sortedGenreValues}
            handleStateChange={handleStateChange}
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs>
          <InitialReleaseInput
            initialReleasePlatformTypeNames={initialReleasePlatformTypeNames}
            initialReleasePlatformValues={sortedReleasePlatformValues}
            handleStateChange={handleStateChange}
          />
        </Grid>
      </Grid>
      <Grid item xs sx={{ display: 'flex' }}>
        <EpisodeTypeInput
          single={single}
          noOfEpisodes={noOfEpisodes}
          duration={duration}
          handleStateChange={handleStateChange}
        />
      </Grid>
      <ShootLocationDescriptionInput
        shootLocation={shootLocation}
        shortDescription={shortDescription}
        handleStateChange={handleStateChange}
      />
    </Grid>
  );
};

export default (memo(Creative): AbstractComponent<PropsType, mixed>);
