/**
 *
 * Rights
 * @format
 * @flow
 *
 */

import {
  ControlPoint as ControlPointIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { Box, Button, Divider, Grid } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useCallback, useContext } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';

import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import TextGeneratingRadioButtonGroup from 'app/components/TextGeneratingRadioButtonGroup/TextGeneratingRadioButtonGroup';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import lodash from 'lodash';
import isEqual from 'lodash/isEqual';
import type { PropsType } from '../types';
import RightsGrantedToThirdPartyItem from './RightsGrantedToThirdPartyItem';
import RightsRetainedByContributorItem from './RightsRetainedByContributorItem';

const Rights: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const radioButton1Text = 'All rights assigned, none excluded';
  const radioButton2Text = 'Rights excluded from assignment';
  const checkbox1Text = 'Rights granted to third party(s)';
  const checkbox2Text = 'Rights retained by Contributor';
  const checkbox3Text =
    'Prod Co obligations subject to this third party signing a quitclaim';
  const checkbox4Text = 'Holdback on rights retained by Contributor';

  const {
    radioLabel1 = radioButton1Text,
    radioValue1 = radioButton1Text,
    textGenerating1 = radioButton1Text,
    radioLabel2 = radioButton2Text,
    radioValue2 = radioButton2Text,
    textGenerating2 = radioButton2Text,
    rightsGrantedToThirdPartyCheckboxLabel = checkbox1Text,
    rightsGrantedToThirdPartyCheckboxText = checkbox1Text,
    rightsRetainedByContributorCheckboxLabel = checkbox2Text,
    rightsRetainedByContributorCheckboxText = checkbox2Text,
    prodCoCheckboxLabel = checkbox3Text,
    prodCoCheckboxText = checkbox3Text,
    holdbackCheckboxLabel = checkbox4Text,
    holdbackCheckboxText = checkbox4Text,
    dropDownValues = [],
    suggestionValues = [],
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    radioValue = radioValue1,
    textValue1 = textGenerating1,
    textValue2,
    rightsGrantedToThirdPartyCheckboxTextValue = '',
    rightsRetainedByContributorCheckboxTextValue = '',
    items1 = [],
    items2 = [],
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const rightsGrantedToThirdPartyDefaultData = {
    rightsType: '',
    specify: '',
    grantedTo: '',
    prodCoCheckboxTextValue: '',
  };

  const rightsRetainedByContributorDefaultData = {
    rightsType: '',
    specify: '',
    holdbackCheckboxTextValue: '',
    holdbackUntil: '',
  };

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      radioValue: radioValue1,
      textValue1: textGenerating1,
      textValue2: '',
      rightsGrantedToThirdPartyCheckboxTextValue: '',
      rightsRetainedByContributorCheckboxTextValue: '',
      items1: [],
      items2: [],
    },
  });

  const onUpdateData = useCallback((data) => {
    onUpdate(contractTermKeyName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rightsGrantedToThirdPartyAddItem = () => {
    onUpdateData({ items1: [...items1, rightsGrantedToThirdPartyDefaultData] });
  };

  const rightsRetainedByContributorAddItem = () => {
    onUpdateData({
      items2: [...items2, rightsRetainedByContributorDefaultData],
    });
  };

  const rightsGrantedToThirdPartyDeleteItem = (index) => {
    const newItems1 = items1.filter((_, i) => i !== index);
    onUpdateData({ items1: newItems1 });
  };

  const rightsRetainedByContributorDeleteItem = (index) => {
    const newItems2 = items2.filter((_, i) => i !== index);
    onUpdateData({ items2: newItems2 });
  };

  const rightsGrantedToThirdPartyDataUpdate = useCallback(
    (targetValue, id, i) => {
      const newItems1 = lodash.cloneDeep(items1);

      newItems1[i][id] = targetValue;
      onUpdateData({ items1: newItems1 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items1]
  );

  const rightsRetainedByContributorDataUpdate = useCallback(
    (targetValue, id, i) => {
      const newItems2 = lodash.cloneDeep(items2);

      newItems2[i][id] = targetValue;
      onUpdateData({ items2: newItems2 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items2]
  );

  const dropDownValRights = dropDownValues.find(
    (item) => item.label === 'Rights'
  ) || { label: '', options: [] };

  const suggestionValForHoldback = suggestionValues.find(
    (item) => item.label === 'Holdback Until'
  );

  const checkRadio2DataIsEmpty = () => {
    const {
      rightsGrantedToThirdPartyCheckboxTextValue = '',
      rightsRetainedByContributorCheckboxTextValue = '',
      items1,
      items2,
    } = contractTermData[contractTermKeyName] || {};

    const termData = {
      rightsGrantedToThirdPartyCheckboxTextValue,
      rightsRetainedByContributorCheckboxTextValue,
      items1,
      items2,
    };
    const termDefaultData = {
      rightsGrantedToThirdPartyCheckboxTextValue: '',
      rightsRetainedByContributorCheckboxTextValue: '',
      items1: [rightsGrantedToThirdPartyDefaultData],
      items2: [rightsRetainedByContributorDefaultData],
    };

    return isEqual(termData, termDefaultData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextGeneratingRadioButtonGroup
          radioValue={radioValue}
          textValue1={textValue1}
          textValue2={textValue2}
          radioLabel1={radioLabel1}
          radioValue1={radioValue1}
          textGenerating1={textGenerating1}
          radioLabel2={radioLabel2}
          radioValue2={radioValue2}
          textGenerating2={textGenerating2}
          radio2ResetData={{
            rightsGrantedToThirdPartyCheckboxTextValue: '',
            rightsRetainedByContributorCheckboxTextValue: '',
            items1: [],
            items2: [],
          }}
          checkRadio2DataIsEmpty={checkRadio2DataIsEmpty}
          setRadio2DefaultData={() => {
            if (!items1?.length) {
              rightsGrantedToThirdPartyAddItem();
            }

            if (!items2?.length) {
              rightsRetainedByContributorAddItem();
            }
          }}
          onUpdate={onUpdateData}
        />
      </Grid>
      {radioValue === 'Rights excluded from assignment' && (
        <>
          <Grid item xs={12} sx={{ marginLeft: 3 }}>
            <TextGeneratingCheckbox
              id="rightsGrantedToThirdPartyCheckboxTextValue"
              label={rightsGrantedToThirdPartyCheckboxLabel}
              value={rightsGrantedToThirdPartyCheckboxTextValue}
              textGenerating={rightsGrantedToThirdPartyCheckboxText}
              onCheck={(id, val) => {
                if (!val) {
                  onUpdateData({
                    items1: [],
                  });
                }
                onUpdateData({ [id]: val });

                if (!items1.length) {
                  rightsGrantedToThirdPartyAddItem();
                }
              }}
              showDialogOnUncheck={true}
            />
            {rightsGrantedToThirdPartyCheckboxTextValue && (
              <Box mt={3}>
                {items1.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index >= 1 && (
                        <Grid item p={4} xs={12}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item xs={12} sx={{ marginLeft: 3 }}>
                        <Grid container justifyContent="flex-end">
                          {index >= 1 && (
                            <Grid item>
                              <Button
                                onClick={() =>
                                  rightsGrantedToThirdPartyDeleteItem(index)
                                }
                                size="large"
                                startIcon={<DeleteIcon />}
                              ></Button>
                            </Grid>
                          )}
                        </Grid>
                        <RightsGrantedToThirdPartyItem
                          item={item}
                          index={index}
                          dropDownVal={dropDownValRights}
                          prodCoCheckboxLabel={prodCoCheckboxLabel}
                          prodCoCheckboxText={prodCoCheckboxText}
                          onDataUpdate={rightsGrantedToThirdPartyDataUpdate}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}

                <Grid item xs={12} mt={3} ml={1}>
                  <Button
                    onClick={() => rightsGrantedToThirdPartyAddItem()}
                    size="large"
                    startIcon={<ControlPointIcon />}
                  ></Button>
                </Grid>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} ml={3} mb={3} mt={2}>
            <TextGeneratingCheckbox
              id="rightsRetainedByContributorCheckboxTextValue"
              label={rightsRetainedByContributorCheckboxLabel}
              value={rightsRetainedByContributorCheckboxTextValue}
              textGenerating={rightsRetainedByContributorCheckboxText}
              onCheck={(id, val) => {
                if (!val) {
                  onUpdateData({
                    items2: [],
                  });
                }
                onUpdateData({ [id]: val });

                if (!items2.length) {
                  rightsRetainedByContributorAddItem();
                }
              }}
              showDialogOnUncheck={true}
            />
            {rightsRetainedByContributorCheckboxTextValue && (
              <Box mt={3}>
                {items2.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index >= 1 && (
                        <Grid item p={4} xs={12}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item xs={12} sx={{ marginLeft: 3 }}>
                        <Grid container justifyContent="flex-end">
                          {index >= 1 && (
                            <Grid item>
                              <Button
                                onClick={() =>
                                  rightsRetainedByContributorDeleteItem(index)
                                }
                                size="large"
                                startIcon={<DeleteIcon />}
                              ></Button>
                            </Grid>
                          )}
                        </Grid>
                        <RightsRetainedByContributorItem
                          item={item}
                          index={index}
                          holdbackCheckboxLabel={holdbackCheckboxLabel}
                          holdbackCheckboxText={holdbackCheckboxText}
                          dropDownVal={dropDownValRights}
                          suggestionVal={suggestionValForHoldback}
                          onDataUpdate={rightsRetainedByContributorDataUpdate}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}

                <Grid item xs={12} mt={3} ml={1}>
                  <Button
                    onClick={() => rightsRetainedByContributorAddItem()}
                    size="large"
                    startIcon={<ControlPointIcon />}
                  ></Button>
                </Grid>
              </Box>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default (memo(Rights): AbstractComponent<PropsType, mixed>);
