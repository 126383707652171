/**
 *
 * ContractingParties
 * @format
 * @flow
 *
 */

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import type { ContractingPartyType, PropsType } from './types';

import { useTheme } from '@mui/material/styles';
import AddressInfo from 'app/components/AddressInfo/AddressInfo';
import Dialog from 'app/components/core/Dialog/Dialog';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { EntityTypeValues } from 'app/services/graphQL/generated-types';
import type { CompanyDetailsReturnType } from 'company/graphQL/useCompanyDetailsQuery';
import { useCompanyDetailsQuery } from 'company/graphQL/useCompanyDetailsQuery';
import type { UpdateCompanyContractingPartiesReturnType } from 'company/graphQL/useUpdateCompanyContractingPartiesMutation';
import { useUpdateCompanyContractingPartiesMutation } from 'company/graphQL/useUpdateCompanyContractingPartiesMutation';
import { useParams } from 'react-router-dom';

const ContractingParties: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const theme = useTheme();
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const {
    data: companyDetailsData,
    loading,
    error,
  }: CompanyDetailsReturnType = useCompanyDetailsQuery({
    _id: cyid,
  });
  const {
    updateCompanyContractingParties,
    data: updateCompanyContractingPartiesData,
    loading: contractingPartiesIsSaving,
  }: UpdateCompanyContractingPartiesReturnType = useUpdateCompanyContractingPartiesMutation();

  const dialogRef = useRef(null);
  const [data, setData] = useState<Array<ContractingPartyType>>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [originalData, setOriginalData] = useState<Array<ContractingPartyType>>(
    []
  );

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  // comment for now
  useEffect(() => {
    if (!!companyDetailsData?.company) {
      const company = companyDetailsData.company || {};
      // $FlowFixMe
      const { __typename: __typename1, ...restCompanyData } = company || {};
      const newContractingParties = restCompanyData?.contractingParties?.map(
        (item) => {
          const {
            // $FlowFixMe
            __typename: __typename2,
            address,
            ...restContractingPartiesData
          } = item || {};

          const {
            // $FlowFixMe
            __typename: __typename3,
            ...restAddressData
          } = address || {};
          // $FlowFixMe
          return {
            ...restContractingPartiesData,
            ...restAddressData,
          };
        }
      );

      // $FlowFixMe
      setData(newContractingParties);
      // $FlowFixMe
      setOriginalData(newContractingParties);
    }
  }, [companyDetailsData]);

  useEffect(() => {
    if (
      !!updateCompanyContractingPartiesData?.updateCompanyContractingParties
        ?.company
    ) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the contracting parties details!',
      });
      setSnackbarOpen(true);
    }
    if (
      updateCompanyContractingPartiesData?.updateCompanyContractingParties
        ?.company === null
    ) {
      setAlertInfo({
        severity: 'error',
        message: 'Error saving the contracting parties details!',
      });
      setSnackbarOpen(true);
    }
  }, [updateCompanyContractingPartiesData]);

  const handleCheckChanges = () => {
    const orgItem = JSON.stringify(originalData);
    const dataItem = JSON.stringify(data);
    if (orgItem === dataItem) {
      setHasChanges(false);
    } else {
      setHasChanges(true);
    }
  };
  useEffect(() => {
    handleCheckChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!companyDetailsData?.company) return <p>Not found</p>;

  const defaultContractingPartyData = {
    name: '',
    type: EntityTypeValues.Company,
    abn: '',
    email: data[0]?.email || '',
    street: data[0]?.street || '',
    suburb: data[0]?.suburb || '',
    state: data[0]?.state || '',
    postcode: data[0]?.postcode || '',
    country: data[0]?.country || '',
    number: data[0]?.number || '',
    isCheckUseAccountDetails: false,
  };

  const handleStateChange = (
    fieldName: string,
    fieldValue: any,
    index: number
  ) => {
    const newItems = [...data];
    const currItem = newItems[index];
    const newItem = {
      ...currItem,
      [fieldName]: fieldValue,
    };
    newItems[index] = newItem;
    setData(newItems);
  };

  const handleUpdateCompanyDetails = () => {
    updateCompanyContractingParties({
      variables: {
        input: {
          _id: cyid,
          contractingPartyDetails: data.map((item) => {
            const {
              isCheckUseAccountDetails,
              street = '',
              suburb,
              state,
              postcode = '',
              country = '',
              number = '',
              abn,
              ...restItem
            } = item || {};
            // $FlowFixMe
            return {
              ...restItem,
              abn,
              acn: 0,
              address: {
                number: Number(number),
                street,
                suburb,
                state,
                postcode,
                country,
              },
            };
          }),
        },
      },
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAddContractingParty = () => {
    const newItems = [...data, defaultContractingPartyData];
    setData(newItems);
  };

  const handleDelete = (index) => {
    const newItems = data.filter((item, i) => i !== index);
    setData(newItems);
  };

  return (
    <Grid container spacing={2}>
      <Dialog
        // $FlowFixMe
        ref={dialogRef}
        title="Cancel Changes"
        labelTwo="Yes"
        labelOne="No"
        onClickTwo={() => {
          dialogRef?.current?.open(false);
          setHasChanges(false);
          setData(originalData);
        }}
        showClose={false}
      >
        Are you sure want to cancel the changes?
      </Dialog>
      {data.map((item, idx) => {
        const {
          name,
          type,
          street,
          suburb,
          state,
          postcode,
          country,
          number,
          abn,
          email,
        } = item;
        return (
          <Fragment key={idx.toString()}>
            <Grid item>
              <Paper elevation={1}>
                <Grid
                  container
                  justifyContent={
                    data.length > 0
                      ? 'space-between'
                      : type === EntityTypeValues.Individual
                      ? 'space-between'
                      : 'flex-end'
                  }
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      sx={{
                        marginBottom: '15px',
                        color: theme.palette.grey['800'],
                        width: '100%',
                      }}
                    >
                      {`Contracting Party ${idx + 1}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {idx !== 0 && (
                      <Grid item>
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            handleDelete(idx);
                          }}
                        ></Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <TextField
                    id="name"
                    label="Name"
                    value={name}
                    onBlur={(id, val) => {
                      handleStateChange(id, val, idx);
                    }}
                    tooltipBody="Include full name company name here (including Pty Ltd etc)."
                  />
                </Grid>
                <Grid container mb={4}>
                  <Select
                    id="type"
                    label="Type"
                    value={type}
                    onSelectOption={(item) => {
                      !Array.isArray(item) &&
                        handleStateChange('type', item.value, idx);
                    }}
                    options={[
                      {
                        value: EntityTypeValues.Company,
                        label: 'Company (e.g. Pty Ltd)',
                      },
                      {
                        value: EntityTypeValues.Individual,
                        label: 'Individual (person)',
                      },
                    ]}
                  />
                </Grid>
                <AddressInfo
                  street={street}
                  suburb={suburb}
                  state={state}
                  postcode={postcode}
                  country={country}
                  number={number}
                  currFieldIndex={idx}
                  onUpdateData={handleStateChange}
                />
                <Grid container>
                  <Grid item lg={5.4} md={5.4} xs={5.4}>
                    <TextField
                      id="abn"
                      label="ABN"
                      value={abn}
                      numberOnly
                      onBlur={(id, val) => {
                        handleStateChange(id, val, idx);
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextField
                      id="email"
                      label="Email Contact"
                      value={email}
                      onBlur={(id, val) => {
                        handleStateChange(id, val, idx);
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Fragment>
        );
      })}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item ml={1}>
          <Tooltip
            body={`${
              !data.length
                ? 'Add a contracting party'
                : 'Add another contracting party'
            }`}
            placement="top"
          >
            <Button
              onClick={() => handleAddContractingParty()}
              startIcon={<AddIcon />}
              color="primary"
              aria-label="add"
            >
              <Typography color="primary" textTransform="none">
                Add Party
              </Typography>
            </Button>
          </Tooltip>
        </Grid>
        {data.length > 0 && (
          <Grid item spacing={2} justifyContent="space-between">
            <LoadingButton
              isLoading={contractingPartiesIsSaving}
              loadingText="Cancel"
              defaultText="Cancel"
              // $FlowFixMe
              onClick={() => dialogRef?.current?.open(true)}
              disabled={contractingPartiesIsSaving ? true : !hasChanges}
              containerStyle={{ marginRight: 10 }}
            />
            <LoadingButton
              isLoading={contractingPartiesIsSaving}
              loadingText="Saving..."
              defaultText="Save"
              onClick={handleUpdateCompanyDetails}
              disabled={!hasChanges}
            />
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default (memo(ContractingParties): AbstractComponent<PropsType, mixed>);
