import { InputBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/system';

const drawerWidth = 256;

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    backgroundColor: '#F2096f3',
    height: '56px',
  },
  contentBoxWrapper: {
    maxWidth: '100%',
    display: 'flex',
    paddingTop: '56px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  navBox: {
    // width: { sm: `calc(${drawerWidth}px + 1)` },
    // flexShrink: { sm: 0 },
    top: '56px',
    width: drawerWidth,
    flex: '1 0 auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    bottom: '0px',
    height: 'auto',
    padding: '24px 16px',
    willChange: 'margin, box-shadow',
    transition: 'width 195ms cubic-bezier(0.4, 0, 1, 1) 0ms',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'fixed',
  },
  mobileDraw: {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  },
  viewBox: {
    width: '100%',
  },
  marginBox: {
    padding: '40px 24px 50px',
    '&:has(.contract-view)': {
      padding: '0',
    },
    '&:has(.project-view)': {
      padding: '0',
    },
    '&:has(.company-view)': {
      padding: '0',
    },
  },
  root: {
    display: 'flex',
    maxWidth: '100%',
    paddingTop: '56px',
    alignItems: 'center',
    flexDirection: 'column', // this puts the main in the center at the mo
  },
  dcontainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    marginRight: '256px',
    transition: 'margin 195ms cubic-bezier(0.4, 0, 1, 1) 0ms',
    top: '56px',
    zIndex: '20',
  },
  // drawerPaper: {
  //   width: '256px',
  //   top: '56px',
  //   overflowX: 'hidden',
  //   bottom: '0px',
  //   height: 'auto',
  //   padding: '24px 16px',
  //   willChange: 'margin, box-shadow',
  //   transition: 'width 195ms cubic-bezier(0.4, 0, 1, 1) 0ms',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'space-between',
  // },
  // drawList: {
  //   listStyle: 'none',
  //   margin: '0px',
  //   padding: '0px',
  // },
  drawerHeaderold: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: 'auto',
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    height: '60 px',
    backgroundColor: '#6200EE',
  },
  drawerIconsSelectorLink: {
    marginTop: 30,
    marginBottom: 20,
  },
  drawerIcons: {
    fontSize: 25,
    color: '#03DAC6',
    '&:hover': {
      color: '#6200EE',
    },
  },
  drawerText: {
    fontWeight: 600,
    fontSize: 14,
    // whiteSpace: 'normal',
  },
  companyName: {
    marginRight: 10,
    fontSize: 15,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  iconPlus: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#42a5f5',
  marginRight: theme.spacing(3),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, -5),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
