import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  addProjectIconSvg: {
    fontSize: 24,
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
  addContractIconSvg: {
    fontSize: 24,
    '& path': {
      stroke: theme.palette.blue.main,
    },
  },
}));
