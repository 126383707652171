/**
 *
 * DatePicker
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { MainContainer, TextFieldStyled, DatePickerContainer } from './styles';
import React, { memo } from 'react';

import { DatePicker as MUIDatePicker } from '@mui/lab';
import type { PropsType } from './types';
import { Typography } from '@mui/material';

const DatePicker = (props: PropsType): Node => {
  const {
    id,
    label,
    value,
    inputFormat = 'MMM d, yyyy',
    disableMaskedInput = false,
    hidePlaceHolder = false,
    onValueChange,
    style,
    containerStyle,
    renderLinkData,
    disabled = false,
  } = props;

  return (
    <MainContainer style={containerStyle}>
      {label && (
        <Typography
          variant="body2"
          sx={{ marginBottom: 1, color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {label}
        </Typography>
      )}
      <DatePickerContainer>
        <MUIDatePicker
          id={id}
          value={value}
          onChange={(newValue, keyboardInputVal) => {
            if (onValueChange) {
              const date = keyboardInputVal
                ? new Date(keyboardInputVal)
                : new Date(newValue);
              if (!isNaN(date)) {
                onValueChange(id, date?.getTime());
              }
            }
          }}
          renderInput={(params) => {
            const newParams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                placeholder: hidePlaceHolder
                  ? undefined
                  : params.inputProps.placeholder,
              },
              style,
            };

            return <TextFieldStyled {...newParams} />;
          }}
          disableMaskedInput={disableMaskedInput}
          inputFormat={inputFormat}
          disabled={disabled}
        />
        {renderLinkData && renderLinkData}
      </DatePickerContainer>
    </MainContainer>
  );
};

export default (memo(DatePicker): AbstractComponent<PropsType, mixed>);
