import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  searchIconSvg: {
    fontSize: 24,
    '& path': {
      stroke: theme.palette.grey['600'],
    },
  },
  chip: {
    borderRadius: '24px',
    marginRight: '8px',
    background: theme.palette.background.paper,
    color: theme.palette.grey['800'],
    '& span': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
    },
  },
}));
