/**
 *
 * Contract Standard Terms Condition Parser
 * @format
 * @flow
 *
 */

export const getExpressionValue = (condition, contractTermData) => {
  const { termId, operator, conditionalValue, termDataIndex } = condition;
  // eslint-disable-next-line no-unused-vars
  const [_, contractData] =
    contractTermData?.find(([termName, termData], i) => {
      const contractTermId = termName?.split('-')[1];
      if (contractTermId === termId) return termData;
      return undefined;
    }) || [];

  if (!contractData) {
    return;
  }

  if (operator === '==') {
    const pathString = termDataIndex.split('.');
    const pathOne = pathString[0];
    const pathTwo = pathString[1];
    const setData = contractData[pathOne];
    const termDataPath = !Array.isArray(setData)
      ? ''
      : setData[0]?.pathTwo;
    return (
      // $FlowFixMe
      termDataPath?.toString()?.toLowerCase() ===
      conditionalValue?.toString()?.toLowerCase()
    );
  } else if (operator === '!=') {
    return (
      // $FlowFixMe
      contractData[termDataIndex]?.toString()?.toLowerCase() !==
      conditionalValue.toString().toLowerCase()
    );
  } else if (operator === '>') {
    // $FlowFixMe
    return contractData[termDataIndex] > conditionalValue;
  } else if (operator === '<') {
    // $FlowFixMe
    return contractData[termDataIndex] < conditionalValue;
  } else {
    return undefined;
  }
};
