/**
 *
 * NamedCurrencyField
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext, useEffect } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const NamedCurrencyField = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    width = 6,
    label = '',
    formatMoney = true,
    numberOnly = true,
    helperText = '',
    placeholder = '',
    startAdornmentString = '',
    endAdornmentString = '',
    linkData,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const { renderLinkData, getLinkDataProps } = useLinkDataComponent();
  const { showSync, fieldId } = getLinkDataProps(linkData, 'value') || {};
  const { value = '', currency = {} }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const currencyStaticValue = {
    symbol: 'AU$',
    name: 'Australian Dollar',
    code: 'AUD',
    value: 'Australian Dollar',
  };

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      value: '',
      currency: {},
    },
  });

  useEffect(() => {
    if (value !== '') {
      onUpdate(contractTermKeyName, { currency: currencyStaticValue });
    }
  }, [value]);

  const updateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid item xs={width}>
      <CurrencyField
        id="value"
        label={label}
        value={value}
        formatMoney={formatMoney}
        numberOnly={numberOnly}
        placeholder={placeholder}
        helperText={helperText}
        startAdornment={<>{startAdornmentString}</>}
        endAdornment={<>{endAdornmentString}</>}
        onBlur={updateData}
        renderLinkData={showSync && renderLinkData(value, fieldId)}
      />
    </Grid>
  );
};

export default (memo(NamedCurrencyField): AbstractComponent<PropsType, mixed>);
