import { Paper as MUIPaper, AppBar as MuiAppBar } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/system';

const drawerWidth = 400;

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: open ? 0 : 200,
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  alignContents: 'center',
}));

export const HeadingsContainerStyled = styled('div')`
  && {
    a {
      color: #4f6fa6;
      text-decoration: none;
    }

    li.active > a {
      color: red;
    }

    li > a:hover {
      color: red;
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      left: '256px',
      width: '256px',
      top: '220px',
      borderRight: `1px solid ${theme.palette.grey['300']}`,
      height: 'calc(100vh - 200px)',
      paddingTop: '20px',
      zIndex: '1',
      visibility: 'visible !important',
      [theme.breakpoints.down('lg')]: {
        left: '70px',
        zIndex: '0',
      },
      '& .MuiAccordion-root': {
        boxShadow: 'none',
        padding: '0 0 0 3px',
        margin: '0',
        '& .MuiAccordionSummary-root': {
          padding: '0 24px',
          height: '48px',
          '&.Mui-expanded': {
            minHeight: 'unset',
            boxShadow: `-3px 0 0 0 ${theme.palette.primary.main}`,
            '& .MuiTypography-root': {
              color: theme.palette.primary.main,
              fontWeight: '600',
              '&:hover': {
                color: theme.palette.primary.clicked,
              },
            },
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '0',
          '& .MuiListItem-root': {
            color: theme.palette.grey['750'],
            paddingLeft: '32px',
            '&.Mui-selected': {
              background: 'none',
              boxShadow: `-3px 0 0 0 ${theme.palette.primary.main}`,
              '& .MuiTypography-root': {
                color: theme.palette.primary.main,
              },
            },
          },
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        '& svg > path': {
          stroke: theme.palette.grey['700'],
        },
      },
      '& .MuiTypography-root': {
        textDecoration: 'none',
        color: theme.palette.grey['750'],
        fontWeight: '400',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  drawerInnerContainer: {
    height: '100%',
  },
  accordionDetailsListItem: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const PaperStyled = styled(MUIPaper)`
  && {
    border-radius: 8px;
    background-color: #fff;
    margin-top: 16px;
    margin-right: 10px;
    width: 100%;
  }
`;

export const primaryTitleStyle = { fontWeight: '500', fontFamily: 'Manrope', fontSize: '16px', lineHeight: '20px', width: '288px', height: '20px' }
export const secondaryTitleStyle = {
  fontWeight: '400',
  fontFamily: 'Manrope',
  fontSize: '14px',
  lineHeight: '20px',
  marginTop: 0,
  marginBottom: 0,
}
