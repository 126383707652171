/**
 *
 * TitleInput
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';
import { useTheme } from '@mui/material/styles';

const TitleInput: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const theme = useTheme();
  const { title, handleStateChange } = props;

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ marginBottom: 1 }}>
          Title*
        </Typography>
        <Typography variant="body2" color={theme.palette.grey['750']}>
          Add a project name, or a working title to identify it. You can change
          this anytime.
        </Typography>
        <TextField
          id="title"
          label=""
          value={title}
          onBlur={handleStateChange}
        />
      </Grid>
    </>
  );
};

export default (memo(TitleInput): AbstractComponent<PropsType, mixed>);
