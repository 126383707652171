/**
 *
 * ProjectNewEdit
 * @format
 * @flow
 *
 */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Creative from './Creative/Creative';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import type { Node } from 'react';
import ProductionSchedule from './ProductionSchedule/ProductionSchedule';
import Spv from './Spv/Spv';
// $FlowFixMe
import { format } from 'date-fns';
import { omit } from 'lodash';
import { useCreateProjectMutation } from 'project/graphQL/useCreateProjectMutation';
import { useProjectDetailsQuery } from 'project/graphQL/useProjectDetailsQuery';
// $FlowFixMe
import { useTheme } from '@mui/material/styles';
import { useUISelectValuesConfigQuery } from 'project/graphQL/useUISelectValuesConfigQuery';
import { useUpdateProjectMutation } from 'project/graphQL/useUpdateProjectMutation';

const ProjectNewEdit = (): Node => {
  const theme = useTheme();
  const history = useHistory();
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const ptid = routeParams.ptid || '';
  const { data: configData } = useUISelectValuesConfigQuery({ skip: !!ptid });

  const {
    data: projectData,
    loading: projectLoading,
    // eslint-disable-next-line no-unused-vars
    error: projectError,
  } = useProjectDetailsQuery({
    _id: ptid,
    skip: !ptid,
  });

  const {
    createProject,
    data: createProjectData,
    loading: createProjectLoading,
  } = useCreateProjectMutation();

  const {
    updateProject,
    data: updateProjectData,
    loading: updateProjectLoading,
  } = useUpdateProjectMutation();

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  const [creativeCombinedState, setCreativeCombinedState] = useState({
    title: '',
    genreTypeNames: [],
    single: true,
    noOfEpisodes: 1,
    duration: 0,
    initialReleasePlatformTypeNames: [],
    shootLocation: '',
    shortDescription: '',
  });

  const defaultScheduleDateVal = format(new Date().getTime(), 'yyyy-MM-dd');
  const defaultScheduleNameVal = '';
  const [scheduleCombinedState, setScheduleCombinedState] = useState({
    preProductionStart: defaultScheduleDateVal,
    preProductionEnd: defaultScheduleDateVal,
    productionStart: defaultScheduleDateVal,
    productionEnd: defaultScheduleDateVal,
    postProductionStart: defaultScheduleDateVal,
    postProductionEnd: defaultScheduleDateVal,
    roughCutDelivery: [
      { name: defaultScheduleNameVal, date: defaultScheduleDateVal },
    ],
    fineCutDelivery: [
      { name: defaultScheduleNameVal, date: defaultScheduleDateVal },
    ],
    finalMaterialsDeliveryStart: defaultScheduleDateVal,
    finalMaterialsDeliveryEnd: defaultScheduleDateVal,
  });

  const [spvsCombinedState, setSPVsCombinedState] = useState({
    companyName: '',
    type: '',
    abn: '',
    acn: 0,
    address: {
      number: 0,
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
  });

  useEffect(() => {
    if (!!createProjectData || !!updateProjectData) {
      setAlertInfo({
        severity: 'success',
        ...(!!createProjectData && {
          message: 'Success creating the project!',
        }),
        // $FlowFixMe
        ...(!!updateProjectData && {
          message: 'Success updating the project!',
        }),
      });
      setSnackbarOpen(true);
      setTimeout(() => {
        const projectId =
          ptid || createProjectData?.createProject?.project?._id;
        if (projectId) {
          history.push(`/dashboard/${cyid}/project/${projectId}?tab=1`);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProjectData, updateProjectData]);

  const onCreativeDataChanged = (description) => {
    // $FlowFixMe
    setCreativeCombinedState((prevState) => ({
      ...prevState,
      ...description,
    }));
  };

  const handleProductionScheduleDataChanged = (schedule) => {
    // $FlowFixMe
    setScheduleCombinedState((prevState) => ({
      ...prevState,
      ...schedule,
    }));
  };

  const handleSPVsDataChanged = (spv) => {
    // $FlowFixMe
    setSPVsCombinedState((prevState) => ({
      ...prevState,
      ...spv,
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getProjectVariables = () => {
    const {
      title,
      genreTypeNames,
      single,
      noOfEpisodes,
      duration,
      initialReleasePlatformTypeNames,
      shootLocation,
      shortDescription,
    } = creativeCombinedState;

    const {
      preProductionStart,
      preProductionEnd,
      productionStart,
      productionEnd,
      postProductionStart,
      postProductionEnd,
      finalMaterialsDeliveryStart,
      finalMaterialsDeliveryEnd,
      roughCutDelivery,
      fineCutDelivery,
    } = scheduleCombinedState;

    const { companyName, type, abn, acn, address } = spvsCombinedState;
    const {
      number = 0,
      street = '',
      suburb = '',
      state = '',
      postcode = '',
      country = '',
    } = address || {};

    const projectVariables = {
      input: {
        company: cyid,
        title,
        genreTypeNames,
        single,
        noOfEpisodes,
        duration,
        currency: 'AUD',
        form: 'test form',
        initialReleasePlatformTypeNames,
        developmentBudgets: [],
        productionBudget: 0,
        shortDescription,
        shootLocation,
        schedule: {
          stageTypeId: 0,
          preProductionStart: format(
            new Date(preProductionStart),
            'yyyy-MM-dd'
          ),
          preProductionEnd: format(new Date(preProductionEnd), 'yyyy-MM-dd'),
          productionStart: format(new Date(productionStart), 'yyyy-MM-dd'),
          productionEnd: format(new Date(productionEnd), 'yyyy-MM-dd'),
          postProductionStart: format(
            new Date(postProductionStart),
            'yyyy-MM-dd'
          ),
          postProductionEnd: format(new Date(postProductionEnd), 'yyyy-MM-dd'),
          finalMaterialsDeliveryStart: format(
            new Date(finalMaterialsDeliveryStart),
            'yyyy-MM-dd'
          ),
          finalMaterialsDeliveryEnd: format(
            new Date(finalMaterialsDeliveryEnd),
            'yyyy-MM-dd'
          ),
          roughCutDelivery: roughCutDelivery?.map(
            // $FlowFixMe
            ({ __typename, date, name }) => ({
              date: format(new Date(date), 'yyyy-MM-dd'),
              name,
            })
          ),
          fineCutDelivery: fineCutDelivery?.map(
            // $FlowFixMe
            ({ __typename, date, name }) => ({
              date: format(new Date(date), 'yyyy-MM-dd'),
              name,
            })
          ),
        },
        companySPVs: [
          {
            companyName,
            type,
            abn,
            acn: Number(acn),
            address: {
              number,
              street,
              suburb,
              state,
              postcode,
              country,
            },
          },
        ],
      },
    };

    return projectVariables;
  };

  const handleCreateProject = () => {
    createProject({
      // $FlowFixMe
      variables: {
        ...getProjectVariables(),
      },
    });
  };

  const handleUpdateProject = () => {
    updateProject({
      variables: {
        input: {
          _id: ptid,
          ...getProjectVariables().input,
        },
      },
    });
  };

  if (ptid && projectLoading) return <p>Loading...</p>;
  if (ptid && projectError) return <p>{JSON.stringify(projectError)}</p>;
  if (ptid && !projectData?.project) return <p>Not found</p>;

  const { project } = projectData || {};
  const {
    genreValues: projectGenreValues,
    releasePlatformValues: projectReleasePlatformValues,
    schedule,
    companySPVs,
  } = project ?? {};
  const { ...restProjectDetails } =
    omit(project, ['schedule', 'companySPVs']) || {};

  const { genreValues, releasePlatformValues } =
    configData?.uiSelectValuesConfig?.projectDetailSelectValues || {};

  const accordionsArr = [
    {
      title: 'Creative',
      component: (
        <Creative
          creativeDetails={restProjectDetails}
          configTypes={
            ptid
              ? {
                  genreValues: projectGenreValues,
                  releasePlatformValues: projectReleasePlatformValues || [],
                }
              : { genreValues, releasePlatformValues }
          }
          onUpdateData={onCreativeDataChanged}
        />
      ),
    },
    {
      title: 'Production Schedule',
      component: (
        <ProductionSchedule
          // $FlowFixMe
          scheduleDetails={schedule || {}}
          onUpdateData={handleProductionScheduleDataChanged}
        />
      ),
    },
    {
      title: 'SPV Company',
      component: (
        // $FlowFixMe
        <Spv spvs={companySPVs || []} onUpdateData={handleSPVsDataChanged} />
      ),
    },
  ];

  return (
    <>
      <Grid container sx={{ marginBottom: '30px' }}>
        {accordionsArr.map((item) => {
          return (
            <Accordion
              key={item.title}
              sx={{
                borderRadius: '12px',
                marginBottom: '16px',
                '&:before': {
                  display: 'none',
                },
                '&:first-of-type': {
                  borderTopRightRadius: '12px',
                  borderTopLeftRadius: '12px',
                },
                '&:last-of-type': {
                  borderBottomRightRadius: '12px',
                  borderBottomLeftRadius: '12px',
                },
              }}
              elevation={0}
              square={false}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography color={theme.palette.primary.main}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{item.component}</AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item>
          <LoadingButton
            isLoading={false}
            loadingText=""
            defaultText="Cancel"
            containerStyle={{ marginTop: '0' }}
            onClick={() => {
              history.goBack();
            }}
            variant="secondary"
          />
        </Grid>
        <Grid item>
          <LoadingButton
            isLoading={createProjectLoading || updateProjectLoading}
            loadingText={ptid ? 'Updating...' : 'Creating...'}
            defaultText={ptid ? 'Update Project' : 'Create Project'}
            onClick={() => {
              if (ptid) {
                handleUpdateProject();
              } else {
                handleCreateProject();
              }
            }}
            containerStyle={{ marginTop: '0' }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo?.message || ''}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProjectNewEdit;
