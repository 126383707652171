/**
 *
 * ProductionSchedule
 * @format
 * @flow
 *
 */

import { Grid, Typography } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useEffect, useState } from 'react';
import type { PropsType, ScheduleInfoType } from './types';

import type { CutDeliveryItemType } from 'app/commonFlowTypes';
import CutDeliveryItem from './CutDeliveryItem/CutDeliveryItem';
import ScheduleItem from './ScheduleItem/ScheduleItem';
// $FlowFixMe
import { format } from 'date-fns';

const ProductionSchedule: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { scheduleDetails, onUpdateData } = props;
  const defaultDateVal = format(new Date().getTime(), 'dd MMM yyyy');
  const defaultNameVal = '';
  const [data, setData] = useState<ScheduleInfoType>({
    preProductionStart: defaultDateVal,
    preProductionEnd: defaultDateVal,
    productionStart: defaultDateVal,
    productionEnd: defaultDateVal,
    postProductionStart: defaultDateVal,
    postProductionEnd: defaultDateVal,
    roughCutDelivery: [{ name: defaultNameVal, date: defaultDateVal }],
    fineCutDelivery: [{ name: defaultNameVal, date: defaultDateVal }],
    finalMaterialsDeliveryStart: defaultDateVal,
    finalMaterialsDeliveryEnd: defaultDateVal,
  });

  useEffect(() => {
    const isScheduleDetailsEmpty = Object.keys(scheduleDetails || {})
      .filter((key) => key !== '__typename')
      .every(
        (key) =>
          // $FlowFixMe
          scheduleDetails[key] === null ||
          (Array.isArray(scheduleDetails[key]) &&
            scheduleDetails[key].length === 0)
      );

    // for newly created project, scheduleDetails is empty
    if (!isScheduleDetailsEmpty) {
      // $FlowFixMe
      setData((prevState) => {
        const newData = { ...prevState, ...scheduleDetails };
        onUpdateData(newData);
        return newData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    preProductionStart,
    preProductionEnd,
    productionStart,
    productionEnd,
    postProductionStart,
    postProductionEnd,
    roughCutDelivery,
    fineCutDelivery,
    finalMaterialsDeliveryStart,
    finalMaterialsDeliveryEnd,
  } = data;

  const handleUpdateDateData = (fieldName: string, fieldValue: number) => {
    setData((prevState) => {
      const newData = { ...prevState, [fieldName]: fieldValue };
      onUpdateData(newData);
      return newData;
    });
  };

  const onSetDataCutDeliveryItems = (
    fieldName: string,
    newItems: Array<?CutDeliveryItemType>
  ) => {
    setData((prevState) => {
      const newData = { ...prevState, [fieldName]: newItems };
      onUpdateData(newData);
      return newData;
    });
  };

  const handleEditCutDeliveryItems = (
    fieldName: 'roughCutDelivery' | 'fineCutDelivery',
    property: 'date' | 'name',
    value: number | string,
    index: number
  ) => {
    const items =
      fieldName === 'roughCutDelivery' ? roughCutDelivery : fineCutDelivery;
    // we have to do it the verbose way as the shorthand version runs into a
    // strict read-only error
    const newItems = items.map((x) => ({
      name: x?.name || defaultNameVal,
      date: x?.date || defaultDateVal,
    }));
    let newDeliveryItem = {};
    if (property === 'date') {
      newDeliveryItem = {
        name: defaultNameVal,
        ...newItems[index],
        date: typeof value === 'string' ? value : defaultDateVal,
      };
    } else {
      newDeliveryItem = {
        date: defaultDateVal,
        ...newItems[index],
        name: typeof value === 'string' ? value : defaultNameVal,
      };
    }
    newItems[index] = newDeliveryItem;
    // $FlowFixMe
    onSetDataCutDeliveryItems(fieldName, newItems);
  };

  const handleAddCutDeliveryItems = (
    fieldName: 'roughCutDelivery' | 'fineCutDelivery'
  ) => {
    const newItems = [
      ...(fieldName === 'roughCutDelivery'
        ? roughCutDelivery
        : fineCutDelivery),
      { name: '', date: defaultDateVal },
    ];
    onSetDataCutDeliveryItems(fieldName, newItems);
  };

  const handleDeleteCutDeliveryItems = (
    fieldName: 'roughCutDelivery' | 'fineCutDelivery',
    index: number
  ) => {
    const items =
      fieldName === 'roughCutDelivery' ? roughCutDelivery : fineCutDelivery;
    const newItems = items.filter((_, i) => i !== index);
    onSetDataCutDeliveryItems(fieldName, newItems);
  };

  return (
    <Grid container>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}
        sx={{ marginBottom: 2 }}
      >
        <Grid item xs={6}></Grid>
        <Grid item xs={3}>
          <Typography variant="body1">Start</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">End</Typography>
        </Grid>
      </Grid>
      <ScheduleItem
        label="Pre-Production"
        startDateId="preProductionStart"
        startDateVal={preProductionStart}
        endDateId="preProductionEnd"
        endDateVal={preProductionEnd}
        handleUpdateData={handleUpdateDateData}
      />
      <ScheduleItem
        label="Production"
        startDateId="productionStart"
        startDateVal={productionStart}
        endDateId="productionEnd"
        endDateVal={productionEnd}
        handleUpdateData={handleUpdateDateData}
      />
      <ScheduleItem
        label="Post Production"
        startDateId="postProductionStart"
        startDateVal={postProductionStart}
        endDateId="postProductionEnd"
        endDateVal={postProductionEnd}
        handleUpdateData={handleUpdateDateData}
        containerStyle={{ marginTop: 1, marginBottom: 2 }}
      />
      <ScheduleItem
        label="Delivery (start)"
        startDateId="finalMaterialsDeliveryStart"
        startDateVal={finalMaterialsDeliveryStart}
        handleUpdateData={handleUpdateDateData}
      />
      {/* <CutDeliveryItem
        label="Rough cut delivery"
        items={roughCutDelivery}
        fieldName="roughCutDelivery"
        handleEditCutDeliveryItems={handleEditCutDeliveryItems}
        handleDeleteCutDeliveryItems={handleDeleteCutDeliveryItems}
        handleAddCutDeliveryItems={handleAddCutDeliveryItems}
      />
      <CutDeliveryItem
        label="Fine cut delivery"
        items={fineCutDelivery}
        fieldName="fineCutDelivery"
        handleEditCutDeliveryItems={handleEditCutDeliveryItems}
        handleDeleteCutDeliveryItems={handleDeleteCutDeliveryItems}
        handleAddCutDeliveryItems={handleAddCutDeliveryItems}
      /> */}
      <ScheduleItem
        label="Delivery (end)"
        endDateId="finalMaterialsDeliveryEnd"
        endDateVal={finalMaterialsDeliveryEnd}
        handleUpdateData={handleUpdateDateData}
      />
    </Grid>
  );
};

export default (memo(ProductionSchedule): AbstractComponent<PropsType, mixed>);
