import * as components from 'contract/contractTermComponents';

import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ArrowRight,
  ContractDataContainer,
  ContractNavBarContainer,
} from './styles';

import ContractProvider from 'app/contexts/Contract/Contract';
import { getContractPartyName } from 'app/utils/contract';
import ContractBuilder from 'contract/ContractView/ContractBuilder/ContractBuilder';
import ContractNavBar from 'contract/ContractView/ContractNavBar/ContractNavBar';
import ContractTopBar from 'contract/ContractView/ContractTopBar/ContractTopBar';
import type { ContractDetailsReturnType } from 'contract/graphQL/useContractDetailsQuery';
import { useContractDetailsQuery } from 'contract/graphQL/useContractDetailsQuery';
import { useParams } from 'react-router-dom';

// will sit at the end of the url:
// dashboard/:cyid/project/:ptid/contract/:ctid
// should get the project title from the project data
// should get the contract title from the contract data
// should get the contract deal terms from the contract data
const ContractView = ({ title, subtitle, description }) => {
  const { cyid, ptid, ctid } = useParams();
  const { loading, error, data }: ContractDetailsReturnType =
    useContractDetailsQuery({ _id: ctid });
  const [contractData, setContractData] = useState(undefined);
  const [navbarCollapse, setNavbarCollapse] = useState(true);

  useEffect(() => {
    if (loading === false && data) {
      setContractData(data?.contract);
    }
  }, [data, loading, setContractData]);

  if (loading || !contractData) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const partyName = getContractPartyName(contractData);
  const onToggleDrawer = () => {
    setNavbarCollapse(!navbarCollapse);
  };

  return (
    <div className="contract-view" style={{ width: '100%' }}>
      <ContractProvider>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          flexWrap="wrap"
        >
          <ContractTopBar
            contractData={contractData || {}}
            ptid={ptid}
            cyid={cyid}
            partyName={partyName}
          />
          <Box sx={{ display: 'flex' }}>
            <ContractNavBarContainer open={navbarCollapse}>
              <ArrowRight
                open={navbarCollapse}
                onClick={() => onToggleDrawer()}
              />
              <ContractNavBar
                contractData={contractData}
                open={navbarCollapse}
              />
            </ContractNavBarContainer>
            <ContractDataContainer open={navbarCollapse}>
              {contractData && (
                <ContractBuilder
                  components={components}
                  contractData={contractData || {}}
                />
              )}
            </ContractDataContainer>
          </Box>
        </Stack>
      </ContractProvider>
    </div>
  );
};

export default ContractView;
