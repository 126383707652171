/**
 *
 * ContractStandardTerms
 * @format
 * @flow
 *
 */

import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext, useRef, useState } from 'react';
import {
  AccordionStyled,
  AddDetailsIcon,
  ClauseParagraphsContainer,
} from './styles';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import Tooltip from 'app/components/Tooltip/Tooltip';
import Dialog from 'app/components/core/Dialog/Dialog';
import IconButton from 'app/components/core/IconButton/IconButton';
import { ContractContext } from 'app/contexts/Contract/Contract';
import cloneDeep from 'lodash/cloneDeep';
import AmendmentInputDelete from './AmendmentInputDelete/AmendmentInputDelete';
import { getExpressionValue } from './ContractStandardTermsConditionParser';
import type { PropsType } from './types';

const ContractStandardTerms: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { accordionTitle = 'Standard Terms' } = props;
  const theme = useTheme();
  const {
    contractStandardTermSections = [],
    onSetContractStandardTermSections,
    contractTermData,
    // $FlowFixMe
    isEditMode,
  } = useContext(ContractContext);
  const dialogRef = useRef(null);
  const [selectedTerm, setSelectedTerm] = useState({});
  const [expanded, setExpanded] = useState(false);

  const updateStandardTermsSections = (
    standardTermSectionIdx,
    termIdx,
    val,
    closeAmendmentDialogue = false
  ) => {
    const newContractStandardTermSections = cloneDeep(
      contractStandardTermSections
    );
    newContractStandardTermSections[standardTermSectionIdx].terms[
      termIdx
    ].amendment = val;

    onSetContractStandardTermSections(newContractStandardTermSections);

    if (closeAmendmentDialogue) {
      dialogRef?.current?.open(false);
    }
  };

  const generateClauseParagraphs = (clauseParagraphs) => {
    let orderedList = '';
    let textItems = '';

    clauseParagraphs.forEach((clause, index) => {
      if (clause.isList) {
        // If it's a list item
        textItems += `<li>${clause.text}</li>`;
      } else {
        // If it's not a list item
        if (textItems) {
          orderedList += `<ol>${textItems}</ol>`;
          textItems = '';
        }
        // Add the non-list item
        orderedList += `<p>${clause.text}</p>`;
      }

      // If it's the last item and list items are pending, wrap them in an ordered list
      if (index === clauseParagraphs.length - 1 && textItems) {
        orderedList += `<ol>${textItems}</ol>`;
      }
    });

    return orderedList;
  };

  const handleTextBlur = (standardTermSectionIdx, termIdx, val) => {
    updateStandardTermsSections(standardTermSectionIdx, termIdx, val);
  };

  const handleDeleteAmendment = (standardTermSectionIdx, termIdx) => {
    updateStandardTermsSections(standardTermSectionIdx, termIdx, null);
  };

  return (
    <AccordionStyled
      expanded={expanded}
      key={0}
      elevation={1}
      onChange={(_e, val) => {
        setExpanded(val);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${1}-content`}
        id={`panel${1}-header`}
      >
        <Typography
          variant="h6"
          color={
            expanded ? theme.palette.primary.main : theme.palette.grey['800']
          }
        >
          {accordionTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {contractStandardTermSections?.map(
          (standardTermSections, standardTermSectionIdx) => {
            const { title, terms } = standardTermSections || {};
            return (
              <Grid
                container
                direction="column"
                spacing={2}
                key={standardTermSectionIdx.toString()}
                sx={{ marginBottom: 1 }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: theme.typography.buttonMedium }}
                    mb={2}
                  >
                    {title}
                  </Typography>
                </Grid>
                {terms.map((term, termIdx) => {
                  const { name, clauseParagraphs, amendment, references } =
                    term || {};

                  if (
                    !references?.length ||
                    references?.some((reference) =>
                      getExpressionValue(
                        reference,
                        Object.entries(contractTermData)
                      )
                    )
                  ) {
                    return (
                      <React.Fragment key={termIdx}>
                        <Grid
                          item
                          key={`${standardTermSectionIdx} - ${termIdx}`}
                        >
                          <Grid item xs={12}>
                            <Grid container direction="row" spacing={1}>
                              <Grid item xs={11.5}>
                                <>
                                  <Grid container direction="row">
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="title"
                                        color={theme.palette.grey['750']}
                                      >
                                        {name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <ClauseParagraphsContainer
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            generateClauseParagraphs(
                                              clauseParagraphs
                                            ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              </Grid>
                              <Grid item xs={0.5}>
                                {isEditMode && (
                                  <IconButton
                                    size="large"
                                    onClick={() => {
                                      setSelectedTerm({
                                        standardTermSectionIdx,
                                        termIdx,
                                      });
                                      dialogRef?.current?.open(true);
                                    }}
                                    disabled={
                                      amendment !== undefined &&
                                      amendment !== null
                                    }
                                  >
                                    <Tooltip
                                      trigger="hover"
                                      title="Add Amendment"
                                    >
                                      <AddDetailsIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Dialog
                            // $FlowFixMe
                            ref={dialogRef}
                            title="Add amendment?"
                            labelTwo="OK"
                            onClickTwo={() => {
                              updateStandardTermsSections(
                                selectedTerm.standardTermSectionIdx,
                                selectedTerm.termIdx,
                                '',
                                true
                              );
                            }}
                          >
                            <Typography
                              variant="body"
                              sx={{ marginBottom: '10px' }}
                            >
                              This will affect this standard term only.
                            </Typography>
                          </Dialog>
                          {amendment !== undefined && amendment !== null && (
                            <AmendmentInputDelete
                              value={amendment}
                              sectionIdx={standardTermSectionIdx}
                              termIdx={termIdx}
                              onTextBlur={handleTextBlur}
                              onDelete={handleDeleteAmendment}
                              isEditMode={isEditMode}
                            />
                          )}
                        </Grid>
                        {standardTermSectionIdx !==
                          contractStandardTermSections.length - 1 ||
                        termIdx !== terms.length - 1 ? (
                          <Grid item py={3} xs={12}>
                            <Divider />
                          </Grid>
                        ) : null}
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid>
            );
          }
        )}
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default (memo(ContractStandardTerms): AbstractComponent<
  PropsType,
  mixed
>);
